.home-top-section {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    padding: 20px;
    overflow: hidden; /* Ensures that nothing overflows the section */
  }
  
  .spline-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none; /* Makes sure that the Spline scene doesn't interfere with interaction */
  }
  
  .home-top-section .content {
    position: relative;
    z-index: 1;
    color: white; /* Ensure text is visible */
    text-align: center;
    width: 100%;
  }
  

