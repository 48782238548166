@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Orbitron:wght@400..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Varela+Round&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card-wrapper {
  max-width: 500px;
  max-height: 400px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 14px;
  border: 0.566667px solid rgba(255, 255, 255, 0.48);
  padding: 20px;
  margin-bottom: 20px;
}

.card-top-text {
  color: #ffffff;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 40px;
  font-weight: 500;
}

.card-middle-text {
  color: #333333;
  font-size: 20px;
  font-family: "Open Sans";
  line-height: 24px;
  font-weight: 300;
}

.card-input-wrapper {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  margin-top: 20px;
}

.card-input-wrapper input {
  color: #666666;
  font-size: 16px;
  font-family: "Orbitron", sans-serif;
  letter-spacing: 3px;
  line-height: 23px;
  border: none;
  width: 100%;
}

.card-input-wrapper button {
  width: 75px;
  height: 23px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f9684b;
  color: #ffffff;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 18px;
  outline: none;
}

.primary-text {
  color: #f9684b;
  font-family: "Oswald";
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
}

.secondary-text {
  color: #000000;
  font-family: "Poppins";
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 500;
}

.para-text {
  color: #7f7f7f;
  font-family: "Work Sans";
  line-height: 1.2;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.primary-button {
  cursor: pointer;
  width: 136px;
  height: 33px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f9684b;
  color: #ffffff;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: left;
  outline: none;
}

.section {
  padding: 50px 20px;
  overflow-x: hidden;
}

.section-top-header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-primary-text {
  font-size: 14px;
}

.section-secondary-text {
  font-size: 24px;
  max-width: 600px;
  margin-top: 10px;
}

.section-para-text {
  font-size: 18px;
  margin-top: 10px;
}

.info-card-wrapper {
  max-width: 100%;
  background-color: rgba(255, 241, 228, 0.69);
  border-radius: 23px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  /* opacity: 0.5; */
  padding: 30px;
}

.info-card-top-text {
  color: #000000;
  font-size: 36px;
  font-family: "Orbitron";
  line-height: 47px;
  margin-top: 15px;
}

.info-card-para-text {
  color: #7f7f7f;
  font-family: "Work Sans";
  line-height: 1.4;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 400;
}

.info-card-button {
  cursor: pointer;
  width: 71px;
  height: 18px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f9684b;
  color: #ffffff;
  font-size: 10px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  line-height: 13px;
  text-transform: uppercase;
  outline: none;
}

.copy-icon {
  color: #7f7f7f;
  fill: #7f7f7f;
  font-size: 19px;
  width: 19px;
  height: 19px;
}

.home-bottom-section {
  width: 100%;
  height: calc(100vh + 50px);
  background-color: rgba(255, 241, 228, 0.69);
  padding: 20px;
}

.bg-image-wrapper {
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(
      to bottom,
      rgba(50, 50, 50, 0.3),
      rgba(50, 50, 50, 0.3)
    ),
    url(./assets/images/home-bottom-section.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  display: flex;
  align-items: end;
  padding: 20px;
  border-radius: 40px;
}

.testimonial-box {
  height: 200px;
}

.testimonial-btn {
  height: 50px;
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  font-size: 10px;
  align-items: center;
  font-size: 10px;
  font-family: "Montserrat";
  font-style: italic;
  line-height: 13px;
  color: #7f7f7f;
}

.testimonial-img {
  height: 50px;
}

.testimonial-text {
  color: #000000;
  font-size: 75px;
  font-family: "Orbitron", sans-serif;
  letter-spacing: 1px;
  line-height: 100px;
  display: inline-block;
  opacity: 0; /* Initial opacity to fade in */
  transition: opacity 0.5s ease-out; /* Smooth opacity transition */
  position: absolute;
}

#testimonial-text1 {
  transform: translateX(calc(-100% - 20px)); /* Initial off-screen position */
  margin-bottom: 50px;
}

#testimonial-text2 {
  transform: translateX(calc(100% + 20px)); /* Initial off-screen position */
}


.about-info {
  background-color: rgba(255, 241, 228, 0.69);
  border-radius: 14px;
}

.detail-warapper p {
  color: #030303;
  font-size: 12px;
  font-family: "Work Sans";
  font-weight: 300;
  line-height: 24px;
  text-align: center;
}

.detail-box {
  flex: 1;
}

.img-wrapper {
  width: 110px;
  height: 110px;
  background-color: #ffffff;
  border-radius: 1000px;
  box-shadow: 0px 2px 12px rgba(3, 3, 3, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-wrapper img {
  width: 30px;
}

.contact-top-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.contact-top-sec img {
  width: 40px;
}

.contact-top-sec p {
  color: #1c1c1c;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 26px;
  text-transform: lowercase;
  margin-bottom: 0;
}

.contact-top-sec a {
  color: #1c1c1c;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 26px;
  text-transform: lowercase;
}

.mail-box .card-wrapper {
  background-color: transparent;
  border: none;
  margin: 100px 0px;
}

.mail-box .card-top-text {
  color: #000000;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 40px;
  font-weight: 500;
}

.mail-box .card-middle-text {
  color: #333333;
  font-size: 16px;
  font-family: "Open Sans";
  line-height: 24px;
  font-weight: 300;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .contact-top-sec {
    margin-bottom: 40px;
  }

  .testimonial-box {
    height: 120px;
  }

  .testimonial-text {
    font-size: 30px;
    line-height: 40px;
  }

  .margin-top-resp {
    margin-top: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}
