header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
}

header.header-show {
  position: fixed;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}

.close-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  z-index: 1;
}

.close-btn img {
  width: 100%;
  height: 100%;
}

.top-row {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.top-row button {
  border: none;
  margin-right: 20px;
}

.top-row button img {
  width: 30px;
}

.header-show-left {
  display: flex;
  align-items: start;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  flex-direction: column;
}

.header-show-right {
  position: relative;
  background-color: rgba(255, 241, 228, 0.69);
}

.logo-box {
  display: flex;
  align-items: end;
}

.logo-box a {
  color: #333333;
  font-size: 30px;
  font-family: "Varela Round";
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-decoration: none;
}

.logo-box a span {
  color: #f9684b;
  font-size: 12px;
  font-family: "Oswald";
  line-height: 18px;
  text-transform: uppercase;
}

.header-show-right button {
  border: none;
  margin-right: 20px;
}

.header-show-right button img {
  width: 30px;
}

.tabs {
  position: absolute;
  top: 200px;
}

.tabs .tab {
  display: flex;
  flex-direction: column;
}

.tabs .tab a {
  color: #1c1c1c;
  font-size: 60px;
  font-family: "Poppins";
  line-height: 55px;
  text-decoration: none;
}

.tabs .tab span {
  color: #606060;
  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 300;
  line-height: 18px;
}

.deatils {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  position: absolute;
  bottom: 80px;
}

.deatils a {
  color: #606060;
  font-size: 18px;
  font-family: "Work Sans";
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 6px;
}

.deatils p {
  color: #606060;
  font-size: 16px;
  font-family: "Work Sans";
  font-weight: 300;
  line-height: 1.1;
}

.mail-box {
  margin-top: 60px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .header-show-left {
    position: relative;
    height: 50vh;
  }

  .tabs {
    position: absolute;
    top: 80px;
  }

  .deatils {
    bottom: 50px;
  }

  .header-show-right {
    /* background: red; */
    height: 50vh;
    /* top: 0; */
  }

  .mail-box .card-wrapper {
    background-color: transparent;
    border: none;
    margin: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
